var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Editor',{ref:"editor",attrs:{"icon":"mdi-check","testo-list":"Ordinativi da eseguire","url":"/audit/ordinativo/","args":{stato: 'pianificato'},"fields":_vm.fields,"filters":_vm.filters,"checkbox":"","no-insert":"","no-update":"","no-delete":""},scopedSlots:_vm._u([{key:"item.data_pianificazione",fn:function(ref){
var item = ref.item;
return [_c('LocalDate',{attrs:{"time":"","date":item.data_pianificazione}})]}},{key:"item.stato",fn:function(ref){
var item = ref.item;
return [_c('OrdinativoStato',{attrs:{"stato":item.stato}})]}},{key:"item.stato_requisiti",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.stato_requisiti.eseguiti)+" / "+_vm._s(item.stato_requisiti.totali)+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [[_c('Confirm',{attrs:{"message":"Conferma la compilazione?","item":item,"enabled":!!_vm.rapporto},on:{"confirm":_vm.conferma},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-file-input',{attrs:{"label":"Rapporto"},model:{value:(_vm.rapporto),callback:function ($$v) {_vm.rapporto=$$v},expression:"rapporto"}})]},proxy:true},{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"success"}},'v-btn',attrs,false),on),[_vm._v("Conferma")])]}}],null,true)}),_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.download_rapporto(item)}}},[_vm._v(" Scarica Rapporto ")])],_c('router-link',{attrs:{"to":{name: 'ordinativo_checklist', params: {id: item.id}}}},[_c('v-btn',{attrs:{"small":"","color":"primary"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-check-circle-outline")]),_vm._v(" Compila ")],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }